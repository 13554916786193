<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Service Accounts</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>        
        <create-service-account></create-service-account> 
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >Create, view and manage your service accounts and their access keys.
        These keys can be used for CLI commands and API requests same as user requests, but are 
        not tied to any user.
        Learn more about service accounts and access keys in the official
        <a
          href="https://docs.synpse.net/synpse-core/authentication/service-accounts"
          class="ml-1"
          target="_blank"
          rel="noopener"
          >documentation</a
        >.
        
        </page-subtitle>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="modalRemove" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete service account '{{ removingServiceAccount.id }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click.native="modalRemove = false" class="primary">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn v-on:click.native="deleteServiceAccount(removingServiceAccount)" text class="primary--text">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
     </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && serviceAccounts.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Servie Accounts</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Service acccounts are used to authenticate CLI commands and API requests same as users
                  </page-subtitle>
                </span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="serviceAccounts"
            :search="e3"
          >
            <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
            </template>

            <template v-slot:[`item.roles`]="{ item }">
            <template v-for="(role, i) in item.roles">
              <v-chip
                :key="`${item.userId}-membership-${i}`"
                label
                class="primary--text mr-2"
                outlined
              >
                {{ role.name }}
              </v-chip>
            </template>
          </template>       

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="removeServiceAccountIntent(item)">
                    <v-list-item-title>delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import CreateServiceAccount from './CreateServiceAccount.vue';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    CreateServiceAccount,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'Name', align: 'left', value: 'name', sortable: true },
        { text: 'Descriptipn', value: 'description', align: 'left' },
        { text: 'Roles', value: 'roles', align: 'left', sortable: true },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      headersAccesskeys: [
        { text: 'ID', align: 'left', value: 'id', sortable: true },
        { text: 'Descriptipn', value: 'description', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
      ],
      modalRemove: false,
      modalEdit: false,
      removingServiceAccount: {
        id: '',
        name: ''
      },
      editingServiceAccount: {
        id: '',
        name: ''
      }
    }
  },

  computed: {
    selectedProjectId() {
      return this.$store.state.project.selectedProjectId
    },
    loading() {
      return this.$store.state.iam.loading
    },
    serviceAccounts() {
      return this.$store.state.iam.serviceAccounts
    },
    serviceAccountAccessKeys(){
      return this.$store.state.iam.serviceAccountAccessKeys
    }
  },

  mounted() {
    this.refresh()
  },

  methods: {
    refresh() {
      let q = {
        projectId: this.selectedProjectId,
      }
      this.$store.dispatch('ListServiceAccounts', q)
    },
    removeServiceAccountIntent (removingServiceAccount) {
      // assigning removing input val
      this.removingServiceAccount = removingServiceAccount
      this.modalRemove = true
    },
    deleteServiceAccount () {
       let q = {
        projectId: this.selectedProjectId,
        serviceAccountId: this.removingServiceAccount.id
      }
      this.$store.dispatch('DeleteServiceAccount', q).then(() => {
        this.refresh()
        // closing confirmation modal
        this.modalRemove = false
      })
    },
    deleteServiceAccountAccessKey(accessKeyId){
      let q = {
        projectId: this.selectedProjectId,
        serviceAccountId: this.editingServiceAccount.id,
        accessKeyId: accessKeyId
      }
      this.$store.dispatch('DeleteServiceAccountsAccessKey', q).then(() => {
        // closing confirmation modal
        this.modalEdit = false
         this.editServiceAccount = null
        this.$store.dispatch('CleanupListServiceAccountsAccessKeys')
        this.refresh()
      })
    }
  }
}
</script>